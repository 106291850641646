<template>
  <q-table
    flat
    bordered
    :grid="grid"
    :filter="filterAdvertiser"
    :data="advertisers"
    :columns="columns"
    row-key="id"
    :pagination.sync="pagination"
    :visible-columns="visibleColumns"
    :rows-per-page-options="[10, 20, 50, 100]"
    class="sticky-column-table"
  >
    <template v-slot:top-right>
      <q-btn
        unelevated
        color="primary"
        outline
        :icon="!grid ? 'eva-grid-outline' : 'eva-list-outline'"
        @click="grid = !grid"
        class="q-mr-md"
      />
      <q-select
        v-model="visibleColumns"
        multiple
        outlined
        dense
        options-dense
        display-value="Personalizar Colunas"
        emit-value
        map-options
        :options="columns"
        option-value="name"
        options-cover
        style="min-width: 150px"
      >
        <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
          <q-item v-bind="itemProps" v-on="itemEvents">
            <q-item-section>
              <q-item-label v-html="opt.label"></q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-toggle :value="selected" @input="toggleOption(opt)" />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
      <!-- <q-select multiple v-model="columnsSelect" :options="columns.map(column => column.field)" label="Standard" filled /> -->
    </template>

    <template v-slot:bottom>
      <div class="row items-center" style="width: 100%">
        <span class="q-mr-xs">Registros por página:</span>
        <q-select
          v-model="pagination.rowsPerPage"
          :options="[10, 20, 50, 100]"
          @change="updateRowsPerPage"
          dense
          style="max-width: 60px; padding-left: 8px; padding-right: 8px"
        />
        <span>Total: {{ advertisers.length }}</span>
        <div style="flex-grow: 1"></div>
        <q-pagination
          unelevated
          v-model="pagination.page"
          color="primary"
          :max="Math.ceil(advertisers.length / pagination.rowsPerPage)"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </div>
    </template>

    <template v-slot:body-cell-level="props">
      <q-td :props="props">
        <q-icon name="mdi-medal-outline" size="sm" :style="iconStyle(props.value)"></q-icon>
        <q-tooltip
          v-if="props.value"
          anchor="center right"
          self="center left"
          :offset="[-10, 10]"
          content-style="font-size: 12px"
        >
          {{ props.value == 1 ? 'Bronze' : props.value == 2 ? 'Prata' : props.value == 3 ? 'Ouro' : '' }}
        </q-tooltip>
      </q-td>
    </template>

    <template v-slot:body-cell-business_name="props">
      <q-td :props="props" style="max-width: 180px" class="ellipsis">
        {{ props.row.business_name }}
        <q-tooltip>
          {{ props.row.business_name }}
        </q-tooltip>
      </q-td>
    </template>

    <template v-slot:body-cell-trademark="props">
      <q-td :props="props" style="max-width: 180px" class="ellipsis">
        {{ props.row.trademark }}
        <q-tooltip>
          {{ props.row.trademark }}
        </q-tooltip>
      </q-td>
    </template>

    <template v-slot:body-cell-cnpj="props">
      <q-td :props="props">
        {{ props.value.toUpperCase() }}
      </q-td>
    </template>

    <template v-slot:body-cell-created_at="props">
      <q-td :props="props">
        {{ $moment(props.value).format('L') }}
      </q-td>
    </template>

    <template v-slot:body-cell-status="props">
      <q-td :props="props">
        <q-chip v-if="props.value === 0" text-color="white" color="warning">Pendente</q-chip>
        <q-chip v-if="props.value === 1" text-color="white" color="positive">Ativo</q-chip>
        <q-chip v-if="props.value === 2" text-color="white" color="info">Pausado</q-chip>
        <q-chip v-if="props.value === 3" text-color="white" color="negative">Desabilitado</q-chip>
      </q-td>
    </template>

    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <q-btn unelevated round flat icon="mdi-pencil-outline" color="primary" @click="editAdvertiser(props.row)">
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Editar </q-tooltip>
        </q-btn>
      </q-td>
    </template>
  </q-table>
</template>
<script>
import AdvertiserMixin from '../../mixins/AdvertiserMixin';
export default {
  name: 'ListAdvertiser',
  mixins: [AdvertiserMixin],
  data() {
    return {
      grid: false,
      pagination: {
        sortBy: 'created_at',
        descending: true,
        page: 1,
        rowsPerPage: 10
      },
      visibleColumns: []
    };
  },

  methods: {
    updateRowsPerPage() {
      this.pagination.page = 1;
      this.getAdvertiser();
    },

    editAdvertiser(props) {
      // this.ActionSetAdvertiserSelect(props);
      this.$router.push({ name: 'FormAdvertiser', params: { id: props.id.toString() } });
    }

    //   iconStyle(value) {
    //     let color = '';
    //     switch (value) {
    //       case 1:
    //         color = '#cd7f32';
    //         break;
    //       case 2:
    //         color = '#c0c0c0';
    //         break;
    //       case 3:
    //         color = '#ffd700';
    //         break;
    //       default:
    //         color = '#BFEFFF ';
    //     }
    //     return { color };
    //   }
  },

  created() {
    this.getAdvertiser();

    this.visibleColumns = this.columns.filter((column) => column.name !== 'business_name').map((column) => column.name);
  }
};
</script>

<style></style>
<style lang="sass">
.sticky-column-table
  max-width: 2600px
  thead tr:last-child th:last-child
    background-color: #fff
  td:last-child
    background-color: #fff
  th:last-child,
  td:last-child
    position: sticky
    right: 0
    z-index: 1
</style>
